import { graphql } from "gatsby";
import React from "react";
import News from "../../views/News/News";

export default function NewsPage() {
  return <News />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
