import React, { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ArticlesList } from "../../components/News/ArticlesList";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { Article } from "../../types";
import { Button } from "../../components/shared/Button";
import { useScreenResolution } from "../../hooks/useScreenResolution";
import { Loader } from "../../components/shared/Loader";
import { getPageSize } from "../../utils";
import { PageHead } from "../../components/shared/PageHead";
import { NewsSearchBar } from "../../components/News/NewsSearchBar";

function News() {
  const { t } = useTranslation();
  const [page, setPage] = useState({ currentPage: 1, totalPages: 1 });
  const [articles, setArticles] = useState<Article[]>([]);
  const [load, setLoad] = useState({
    isLoading: false,
    finished: false,
  });
  const resolution = useScreenResolution();
  const pageSize = getPageSize(resolution.resolution);

  useEffect(() => {
    const getData = async () => {
      setLoad((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await sendRequestToAPI(
          `
          query($pageSize: Int, $page: Int){
               articles(pagination:{pageSize: $pageSize, page: $page}, sort:"publicationDate:desc"){  
              meta{pagination{
              total
              page
              pageSize
              pageCount}}
              data{attributes{
              publicationDate
              slug
              description
              title
              previewImage{ 
                data{attributes{url}}
              }
              image{
                data{attributes{url}}
              }
              category{
                data{attributes{categoryName
                categoryId}}
              }
            }}
          }
        }
        `,
          {
            pageSize,
            page: page.currentPage,
          }
        );

        setLoad((prevState) => {
          const finished =
            page.currentPage === data.data.articles.meta.pagination.pageCount
              ? { finished: true }
              : {};
          return { ...prevState, isLoading: false, ...finished };
        });
        setArticles((prevState) => [...prevState, ...data.data.articles.data]);
        setPage((prevState) => ({
          ...prevState,
          totalPages: data.data.articles.meta.pagination.pageCount,
        }));
      } catch (err) {
        return err;
      }
    };
    pageSize && getData();
  }, [page.currentPage, pageSize]);

  return (
    <Layout>
      <PageHead title={t("newsHeading")} />
      <ArticleSection>
        <ArticleSecWrap>
          <SearchHead>
            <MobileHeading>{t("newsHeading")}</MobileHeading>
            <DesktopHeading>{t("newsHeading")}</DesktopHeading>
            <NewsSearchBar />
          </SearchHead>
          <ListWrap>
            {!!articles.length ? (
              <>
                <ArticlesList main={true} articles={articles} />
                {!!articles.length && (
                  <PaginationWrap>
                    {!load.finished &&
                      (load.isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          css={{ margin: "60px auto 0 auto" }}
                          onClick={() =>
                            setPage((prevState) => ({
                              ...prevState,
                              currentPage: prevState.currentPage + 1,
                            }))
                          }
                        >
                          {t("loadMore")}
                        </Button>
                      ))}
                  </PaginationWrap>
                )}
              </>
            ) : (
              <Loader size={"xl"} />
            )}
          </ListWrap>
        </ArticleSecWrap>
      </ArticleSection>
    </Layout>
  );
}

export default News;

const SearchHead = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px 16px",
  "@md": {
    padding: "24px 0 8px 0",
    flexDirection: "row",
  },
});

const DesktopHeading = styled("h1", {
  display: "none",
  "@md": {
    display: "block",
    color: "$heading",
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: 700,
    margin: 0,
    textAlign: "start",
  },
});

const MobileHeading = styled("h1", {
  color: "$heading",
  fontSize: "32px",
  lineHeight: "40px",
  fontWeight: 700,
  textAlign: "center",
  margin: "0px 0px 32px 0px",
  "@md": {
    display: "none",
  },
});

const ArticleSecWrap = styled("div", {
  padding: "0 16px 60px 16px",
  display: "flex",
  flexDirection: "column",
  "@md": {
    padding: "0px 0px 80px 0px",
    maxWidth: 1280,
    margin: "0 auto",
  },
});

const PaginationWrap = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const ListWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const ArticleSection = styled("section", {
  backgroundColor: "$secondaryBackground",
});
