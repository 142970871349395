import React, { useEffect, useReducer, useState } from "react";
import { styled } from "../../../stitches.config";
import { Categories } from "./Categories";
import { SearchInput } from "./SearchInput";
import Search from "../../../static/icons/common/news-search-icon.svg";
import Arrow from "../../../static/icons/common/news-search-arrow.svg";
import { CategoriesMobileMenu } from "./CategoriesMobileMenu";
import { Article, Category } from "../../types";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useScreenResolution } from "../../hooks/useScreenResolution";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const NewsSearchBar = () => {
  const [isCategoriesOpen, toggle] = useReducer((val) => !val, false);
  const [latestArticles, setLatestArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const { resolution } = useScreenResolution();
  const { language } = useI18next();
  const { t } = useTranslation();
  const { ref } = useClickOutside({
    onClickOutside: toggle,
    isOpen: isCategoriesOpen,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await sendRequestToAPI(
          `query($locale:I18NLocaleCode ){
              newsCategories(locale: $locale, filters:{articles:{slug: {notNull: true}}}, sort:"categoryName:asc"){
                  data{
                    attributes{
                      categoryId
                      categoryName
                    }
                  }
                }
              articles(locale: $locale, pagination:{limit: 3}, sort:"publicationDate:desc"){  
              meta{pagination{
              total
              page
              pageSize
              pageCount}}
              data{attributes{
              publicationDate
              description
              title
              slug
              previewImage{
                data{attributes{url}}
              }
              image{
                data{attributes{url}}
              }
              category{
                data{attributes{categoryName
                categoryId}}
              }
            }}
          }
        }`,
          { locale: language }
        );
        setLatestArticles(data.data.articles.data);
        setCategories(data.data.newsCategories.data);
      } catch (err) {
        return err;
      }
    };
    getData();
  }, []);

  return (
    <Container>
      <SearchInput type="gray" />
      <OpenCategoriesWrap>
        <OpenCategories
          css={{ pointerEvents: isCategoriesOpen ? "none" : "" }}
          onClick={() => toggle()}
          isCategoriesOpen={!!isCategoriesOpen}
        >
          <Search />
          <Text>{t("news.search.bar")}</Text>
          <StyledArrow opened={isCategoriesOpen} />
        </OpenCategories>
      </OpenCategoriesWrap>
      {isCategoriesOpen && (
        <>
          {resolution === "desktop" ? (
            <AbsoluteContainer
              ref={(interalRef) => {
                ref.current = interalRef;
              }}
            >
              <Categories
                categories={categories}
                latestArticles={latestArticles}
                isInRow={true}
              />
            </AbsoluteContainer>
          ) : (
            <MobileWrap>
              <CategoriesMobileMenu
                categories={categories}
                isOpen={isCategoriesOpen}
                toggle={toggle}
              />
            </MobileWrap>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  width: "100%",
  maxWidth: 808,
  height: "64px",
  "@md": {
    width: "unset",
    gap: 30,
  },
});

const MobileWrap = styled("div", {
  "@md": {
    display: "none",
  },
});

const OpenCategories = styled("div", {
  display: "flex",
  justifyContent: "center",
  userSelect: "none",
  alignItems: "center",
  gap: 10,
  padding: "14px 12px",
  borderRadius: "12px 12px 0 0",
  cursor: "pointer",
  variants: {
    isCategoriesOpen: {
      true: {
        background: "#DBDBDB",
      },
    },
  },
});
const OpenCategoriesWrap = styled("div", {
  cursor: "pointer",
});

const AbsoluteContainer = styled("div", {
  display: "none",
  "@md": {
    filter:
      "drop-shadow(0px 122px 49px rgba(0, 0, 0, 0.01)) drop-shadow(0px 69px 41px rgba(0, 0, 0, 0.03)) drop-shadow(0px 31px 31px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 17px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))",
    zIndex: 5,
    display: "block",
    position: "absolute",
    background: "$white",
    right: 0,
    top: 70,
    borderRadius: 10,
    padding: 32,
    width: 700,
  },
});

const Text = styled("p", {
  margin: 0,
  color: "$categoriesText",
  fontWeight: 400,
  fontSize: 17,
  lineHeight: "24px",
});

const StyledArrow = styled(Arrow, {
  transition: "all 500ms ease",
  variants: {
    opened: {
      true: {
        transform: "rotate(180deg)",
      },
    },
  },
});
